import {inject} from '@angular/core';
import {Router, UrlTree} from '@angular/router';
import {ConfigService} from '@q9elements/ui-kit/common';
import {isEmpty} from 'lodash';

import {Q9maCookieService} from '../services/cookie.service';

export const AccountVerifyGuard = (route: any): boolean | UrlTree => {
  const cookieService = inject(Q9maCookieService);
  const tokenInParams = route.params['token'];
  const redirectParams = route.queryParams;
  const router = inject(Router);

  if (!isEmpty(redirectParams)) {
    const redirectUrl = buildRedirectUrl(redirectParams);

    cookieService.updateRedirect(redirectUrl);
  }

  if (tokenInParams) {
    const token = tokenInParams.replace(/elements/g, '.');

    cookieService.setToken(token);

    return router.createUrlTree(['account-accept']);
  }

  const isTokenValid = cookieService.isTokenValid();

  if (!isTokenValid && !tokenInParams) {
    return router.createUrlTree(['signin']);
  }

  if (isTokenValid && tokenInParams) {
    router.createUrlTree(['account-accept']);
  }

  return true;
};

function buildRedirectUrl(params: {refModelId?: string; diagramId?: string; v: string}) {
  if (params.refModelId) {
    return `${ConfigService.environment.REF_MODEL_APP}/refmodel/${params.refModelId}`;
  }

  if (params.diagramId) {
    return `${ConfigService!.environment.DIAGRAM_URL}/diagram/${params.diagramId}?parent=HD-APP&v=${params.v}`;
  }

  return 't';
}
