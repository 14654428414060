import {inject} from '@angular/core';
import {CanActivateFn, Router} from '@angular/router';
import {SessionService} from '@q9elements/ui-kit/common';

export const SpaceIndexValidateGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const sessionService = inject(SessionService);

  const {index} = route.params;
  const urlTree = router.parseUrl(state.url);
  const segments = urlTree.root.children['primary']?.segments.map(segment => segment.path) || [];
  const isValidSpaceIndex = Number.isInteger(+index);

  if (!isValidSpaceIndex) {
    const queryParams = route.queryParams ?? null;

    segments[1] = String(sessionService.teamIndex());

    return router.createUrlTree(segments, {queryParams});
  }

  return true;
};
