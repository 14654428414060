import {Injectable} from '@angular/core';
import {getCookieOptions} from '@q9elements/ui-kit/common';
import {CookieService} from 'ngx-cookie-service';

import {AUTH_TOKEN, REDIRECT} from '../models/auth';

@Injectable({providedIn: 'root'})
export class Q9maCookieService {
  constructor(private cookieService: CookieService) {}

  private get cookieOptions() {
    const {path, domain, secure, sameSite} = getCookieOptions();

    return [path, domain, secure, sameSite] as Array<string>;
  }

  setToken(token: string, remember: boolean = false) {
    this.deleteToken();

    let date;

    if (remember) {
      date = new Date();

      date.setMonth(date.getMonth() + 1);
    }

    return this.cookieService.set(AUTH_TOKEN, token, date, ...this.cookieOptions);
  }

  isTokenValid(): boolean {
    const authToken = this.cookieService.get(AUTH_TOKEN);

    return Boolean(authToken);
  }

  deleteToken() {
    return this.cookieService.delete(AUTH_TOKEN, ...this.cookieOptions);
  }

  getToken() {
    return this.cookieService.get(AUTH_TOKEN);
  }

  getRedirect() {
    if (this.cookieService.check(REDIRECT)) {
      const redirectUrl = this.cookieService.get(REDIRECT);

      return decodeURIComponent(redirectUrl);
    }

    return null;
  }

  updateRedirect(redirect: string) {
    return this.cookieService.set(REDIRECT, redirect, void 0, ...this.cookieOptions);
  }

  deleteRedirect() {
    return this.cookieService.delete(REDIRECT, ...this.cookieOptions);
  }
}
