import {UserTeam} from '@q9elements/ui-kit/common';

export interface SpaceEntities {
  [key: string]: UserTeam;
}

export interface UserInvitation {
  dataStructureAdmin: boolean;
  email: string;
  importManager: boolean;
  isEditor: boolean;
  message: string;
  releaseAdmin: boolean;
  requirementsManager: boolean;
  resourceAdmin: boolean;
  role: 'editor' | 'viewer';
  teamAdmin: boolean;
  urlLibraryManager: boolean;
  apiTokenManager: boolean;
}

export interface FileSelection {
  blob: Blob;
  fileData: string | ArrayBuffer | null | undefined;
  file: File;
}

export enum TAB_TYPES {
  MY = 'my',
  PUBLIC = 'public'
}
