import {ROUTES_PATH} from './routes-path.enum';

export const MODULES = [
  {
    title: 'SIDEBAR.DASHBOARD',
    icon: 'sidenav:home',
    path: ROUTES_PATH.DASHBOARD
  },
  {
    title: 'SIDEBAR.MAPS_TAB',
    icon: 'sidenav:processes',
    path: ROUTES_PATH.MAPS
  },
  {
    title: 'SIDEBAR.REQUIREMENTS',
    icon: 'sidenav:changes',
    path: ROUTES_PATH.CHANGES
  },
  {
    title: 'SIDEBAR.SF_METADATA',
    icon: 'sidenav:external-orgs',
    path: ROUTES_PATH.SF_ORGS
  },
  {
    title: 'SIDEBAR.REF_MODELS',
    icon: 'sidenav:ref-models',
    path: ROUTES_PATH.REFERENCE_MODEL
  },
  {
    title: 'SIDEBAR.URL_LIBRARY',
    icon: 'sidenav:url-library',
    path: ROUTES_PATH.URL_LIBRARY
  },
  {
    title: 'SIDEBAR.RELEASES',
    icon: 'sidenav:releases',
    path: ROUTES_PATH.RELEASES
  }
];
