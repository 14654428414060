import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';

export const QueryParamsGuard =
  (key: string, allowedValues: string[]) =>
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const router = inject(Router);
    const queryParams = {...route.queryParams};
    const value = queryParams[key];

    if (!value || !allowedValues.includes(value)) {
      queryParams[key] = allowedValues[0];

      return router.createUrlTree([state.url.split('?')[0]], {queryParams});
    }

    return true;
  };
