import {SORT_ORDER} from '@q9elements/ui-kit/components';

import {RELEASES_TABS} from '../models/table';

export function getReleasesSortBy() {
  const ref = new URLSearchParams(window.location.search).get('ref') || RELEASES_TABS.ACTIVE;
  const isActiveRef = ref === RELEASES_TABS.ACTIVE;

  return {
    key: isActiveRef ? 'targetReleaseDate' : 'releaseDate',
    order: isActiveRef ? SORT_ORDER.ASC : SORT_ORDER.DESC
  };
}
