import {Column, TableConfig} from '@q9elements/ui-kit/components';

import {getReleasesSortBy} from '../utils/getSortBy';

export const COLUMNS: Column[] = [
  {
    key: 'name',
    title: 'RELEASE.NAME_HEADER',
    flex: 2,
    sticky: true,
    enableSort: true
  },
  {
    key: 'targetReleaseDate',
    title: 'RELEASE.TARGET_DATE_HEADER',
    flex: 2,
    enableSort: true
  },
  {
    key: 'releaseDate',
    title: 'RELEASE.RELEASE_DATE_HEADER',
    flex: 2,
    enableSort: true
  },
  {
    key: 'numberOfComponents',
    title: 'RELEASE.NO_OF_COMPONENTS_HEADER',
    enableSort: true
  },
  {
    key: 'release_number',
    title: 'RELEASE.RELEASE_NUMBER_HEADER',
    flex: 1,
    enableSort: true
  },
  {
    key: 'type',
    title: 'RELEASE.RELEASE_TYPE',
    enableSort: true
  },
  {
    key: 'riskLevel',
    title: 'RELEASE.RELEASE_RISK',
    enableSort: true
  },
  {
    key: 'training',
    title: 'RELEASE.TRAINING_TAB',
    flex: 2
  }
];

export enum RELEASES_TABS {
  ACTIVE = 'active',
  PUBLISHED = 'published'
}

export const TABLE_CONFIG: TableConfig = {
  enableServerPagination: true,
  enableFiltering: true,
  enableSorting: true,
  columns: COLUMNS,
  sortBy: getReleasesSortBy()
};
