import {DiagramItem, TrainingItem} from '@q9elements/ui-kit/common';

export enum TASKS_TABS {
  ACTIVE = 'active',
  CLOSED = 'closed'
}

export enum TASKS_URGENCY {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low'
}

export enum DIAGRAM_ITEM_STATUS {
  SEEN = 'seen',
  UNSEEN = 'unSeen',
  CANCELLED = 'cancelled',
  DONE = 'done',
  NOT_DONE = 'notDone'
}

export interface DiagramVersionForAuthorisationTaskResponse {
  diagram: string;
  state: string;
  authorisationStr: string | null;
}

export type DiagramItemMeta = Omit<TrainingItem, 'item'> & {item: DiagramItem};
