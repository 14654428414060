import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {
  DialogWidth,
  PermissionsService,
  SessionService,
  REPORT_MODAL_TYPE,
  ChangesType
} from '@q9elements/ui-kit/common';
import {ReportModalComponent} from '@q9elements/ui-kit/components';

import {ROUTES_PATH} from '../../../models/routes-path.enum';

@Injectable()
export class CommonWrapperDialogService {
  constructor(
    private dialog: MatDialog,
    private sessionService: SessionService,
    private permissionsService: PermissionsService,
    private router: Router
  ) {}

  public openReportDialog(modalType: REPORT_MODAL_TYPE) {
    const userInTeam = this.sessionService.user()!.userInTeam!;
    const currentTeam = {
      _id: this.sessionService.teamId(),
      name: userInTeam.team.name,
      planType: userInTeam.team.plan.type,
      teamIndex: this.sessionService.teamIndex(),
      trialExpired: !this.permissionsService.isProActive()
    };
    const urlSegments = this.router.url.split('/');
    const defaultFilter = {priority: null, summary: '', tags: []};
    const anchorTarget = '_blank';
    let lastSegment = urlSegments[urlSegments.length - 1].split('?')[0];

    if (lastSegment === ROUTES_PATH.URL_LIBRARY) {
      lastSegment = 'urlLibrary';
    }

    if (
      lastSegment === ChangesType.STORIES ||
      lastSegment === ChangesType.REQUIREMENTS ||
      lastSegment === ROUTES_PATH.FEEDBACKS
    ) {
      lastSegment = 'requirement';
    }

    this.dialog.open(ReportModalComponent, {
      width: DialogWidth.LG_XL,
      data: {
        currentTeam,
        modalType: modalType,
        generalReportType: lastSegment,
        metadata: {defaultFilter},
        anchorTarget
      }
    });
  }
}
