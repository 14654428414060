import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {SalesforceConnectCallbackGuard} from './salesforce-orgs/guards/salesforce-connect-callback.guard';
import {SalesforceRefreshCallbackGuard} from './salesforce-orgs/guards/salesforce-refresh-callback.guard';
import {AuthWrapperComponent} from './shared/components/auth-wrapper/auth-wrapper.component';
import {CommonWrapperComponent} from './shared/components/common-wrapper/common-wrapper.component';
import {ActiveUserGuard} from './shared/guards/active-user.guard';
import {AuthGuard} from './shared/guards/auth.guard';
import {LogoutGuard} from './shared/guards/logout.guard';
import {QueryParamsGuard} from './shared/guards/query-params.guard';
import {RedirectGuard} from './shared/guards/redirect.guard';
import {SessionGuard} from './shared/guards/session.guard';
import {SpaceIndexValidateGuard} from './shared/guards/space-index-validate.guard';
import {ROUTES_PATH} from './shared/models/routes-path.enum';
import {UserEnterprisePermissionsResolver} from './shared/resolvers/user-enterprise-permissions.resolver';
import {AuthenticationRoutesMatcher, routes as authRoutes} from './shared/routing/auth.routes';
import {CommonRoutingMatcher, routes as commonRoutes} from './shared/routing/common.routes';
import {TASKS_TABS} from './tasks/models/tasks';

const routes: Routes = [
  {path: '', children: [], canActivate: [LogoutGuard], pathMatch: 'full'},
  {
    path: ROUTES_PATH.SF_REFRESH_CALLBACK,
    canActivate: [SalesforceRefreshCallbackGuard],
    children: []
  },
  {
    path: ROUTES_PATH.SF_CONNECT_CALLBACK,
    canActivate: [SalesforceConnectCallbackGuard],
    children: []
  },
  {
    path: ROUTES_PATH.NOT_FOUND,
    loadComponent: () => import('./not-found/not-found.component').then(c => c.NotFoundComponent)
  },
  {
    matcher: AuthenticationRoutesMatcher,
    component: AuthWrapperComponent,
    canActivate: [RedirectGuard],
    canMatch: [ActiveUserGuard, AuthGuard],
    children: authRoutes
  },
  {
    matcher: CommonRoutingMatcher,
    component: CommonWrapperComponent,
    canActivate: [RedirectGuard],
    canMatch: [AuthGuard, SessionGuard],
    resolve: {userEnterprisePermissions: UserEnterprisePermissionsResolver},
    children: [
      {
        path: 't/:index',
        pathMatch: 'prefix',
        canActivate: [SpaceIndexValidateGuard],
        children: commonRoutes
      },
      {
        path: ROUTES_PATH.TASKS,
        canActivate: [QueryParamsGuard('ref', Object.values(TASKS_TABS))],
        loadChildren: () => import('./tasks/tasks.module').then(m => m.TasksModule)
      }
    ]
  },
  {path: '**', redirectTo: ROUTES_PATH.NOT_FOUND}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {enableTracing: false})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
