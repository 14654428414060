import {CommonModule} from '@angular/common';
import {AfterViewInit, Component} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {ActivatedRoute, RouterOutlet} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {
  ConfigService,
  GainSightService,
  IntercomService,
  PermissionsService,
  Q9TransformPipe,
  REPORT_MODAL_TYPE,
  SessionService,
  User
} from '@q9elements/ui-kit/common';
import {
  IconButtonComponent,
  LoadingPlaceholderModule,
  NotificationsModule,
  ReportsModule,
  SidenavModule,
  SupportButtonComponent,
  ThemeSwitcherComponent,
  ToolbarModule,
  TrainingsModule
} from '@q9elements/ui-kit/components';
import {get} from 'lodash';
import {map} from 'rxjs';

import {MODULES} from '../../models/navigation';
import {NavigationService} from '../../services/navigation.service';
import {SpaceNavigationComponent} from '../space-navigation/space-navigation.component';
import {CommonWrapperDialogService} from './services/common-wrapper-dialog.service';

@Component({
  selector: 'q9ma-common-wrapper',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    LoadingPlaceholderModule,
    ToolbarModule,
    MatMenuModule,
    SidenavModule,
    TranslateModule,
    NotificationsModule,
    TrainingsModule,
    ReportsModule,
    SpaceNavigationComponent,
    SupportButtonComponent,
    IconButtonComponent,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    Q9TransformPipe,
    ThemeSwitcherComponent
  ],
  providers: [CommonWrapperDialogService],
  templateUrl: './common-wrapper.component.html',
  styleUrls: ['./common-wrapper.component.scss']
})
export class CommonWrapperComponent implements AfterViewInit {
  readonly modules = MODULES;
  readonly isSysAdmin = this.permissionsService.isSysAdmin;
  readonly isProActive = this.permissionsService.isProActive;
  readonly hasAccessToEnterprise = this.checkAccessToEnterprise();

  constructor(
    private sessionService: SessionService,
    private intercomService: IntercomService,
    private gainSightService: GainSightService,
    private navigationService: NavigationService,
    private permissionsService: PermissionsService,
    private route: ActivatedRoute,
    private commonWrapperDialogService: CommonWrapperDialogService
  ) {}

  ngAfterViewInit() {
    const user = <User>this.sessionService.user();

    this.intercomService.bootIntercom(user);
    this.gainSightService.setupGainSight(user);
  }

  private checkAccessToEnterprise() {
    return toSignal(
      this.route.data.pipe(
        map(({userEnterprisePermissions}) => {
          const hasAccess = get(userEnterprisePermissions, 'hasAccess', false);
          const enterpriseExists = get(userEnterprisePermissions, 'enterpriseExists', false);

          return enterpriseExists && hasAccess;
        })
      )
    );
  }

  navigateTo(path?: string) {
    return this.navigationService.navigateToModule(path);
  }

  openSpaceManagementApp() {
    const url = [ConfigService.environment.TEAM_APP, 't', this.sessionService.teamIndex()].join(
      '/'
    );

    window.open(url, '_blank');
  }

  openSysAdminApp() {
    const url = `${ConfigService.environment.SYS_ADMIN_APP}/spaces`;

    window.open(url, '_blank');
  }

  openCorporateManagementApp() {
    window.open(ConfigService.environment.ENTERPRISE_URL, '_blank');
  }

  public onChange(modalType: REPORT_MODAL_TYPE) {
    this.commonWrapperDialogService.openReportDialog(modalType);
  }
}
