import {getFullName, User, Map} from '@q9elements/ui-kit/common';
import {Column, SORT_ORDER, TableConfig} from '@q9elements/ui-kit/components';
import {capitalize} from 'lodash';

export enum MAP_TABS {
  ACTIVE = 'active',
  DELETED = 'deleted',
  SPACE = 'org'
}

export enum MAP_TYPE {
  ARCHITECTURE = 'Architecture',
  UPN = 'upn',
  BUSINESS_PROCESS = 'Business process',
  ALL = 'All'
}

export const MAP_TYPE_VALUES = {
  UPN: 'upn',
  ARCHITECTURE: 'architecture'
};

export type MapType = (typeof MAP_TYPE_VALUES)[keyof typeof MAP_TYPE_VALUES];

export const COLUMNS: Column[] = [
  {
    key: 'name',
    title: 'MAP.NAME_HEADER',
    flex: 2,
    enableSort: true,
    sticky: true
  },
  {
    key: 'type',
    title: 'GENERAL.TYPE',
    enableSort: true,
    transform: (row: Map) =>
      row.type === MAP_TYPE.UPN ? MAP_TYPE.BUSINESS_PROCESS : capitalize(row.type)
  },
  {
    key: 'owner',
    title: 'MAP.OWNER_HEADER',
    enableSort: true,
    transform: (row: Map) => getFullName(row.author as User)
  },
  {
    key: 'state',
    enableSort: true,
    title: 'MAP.STATE_HEADER',
    transform: (row: Map) => capitalize(row.state)
  },
  {
    key: 'role',
    enableSort: true,
    title: 'MAP.ACCESS_HEADER'
  },
  {
    key: 'tags',
    title: 'Tags'
  }
];

export const TABLE_CONFIG: TableConfig = {
  enableServerPagination: true,
  enableFiltering: true,
  enableSorting: true,
  columns: COLUMNS,
  sortBy: {key: 'name', order: SORT_ORDER.ASC},
  getSortParam(key: string, order: string): {[p: string]: string} {
    return {sort: `${key} ${order.toUpperCase()}`};
  }
};

export const typeSelectConfig = {
  defaultOption: {label: MAP_TYPE.ALL, value: MAP_TYPE.ALL.toLowerCase()},
  options: [
    {label: MAP_TYPE.BUSINESS_PROCESS, value: MAP_TYPE.UPN},
    {label: MAP_TYPE.ARCHITECTURE, value: MAP_TYPE.ARCHITECTURE.toLowerCase()}
  ]
};
